import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import classes from "./HeroCarousel.module.scss";
import { FaAnglesDown } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";

type CreatorTypeCard = {
    image: any;
    image_alt: string;
    title:
        | "welcomePage.heroCarousel.creatorTypes.creatorType1.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType2.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType3.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType4.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType5.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType6.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType7.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType8.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType9.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType10.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType11.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType12.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType13.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType14.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType15.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType16.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType17.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType18.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType19.title"
        | "welcomePage.heroCarousel.creatorTypes.creatorType20.title";
    subtitle:
        | "welcomePage.heroCarousel.creatorTypes.creatorType1.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType2.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType3.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType4.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType5.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType6.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType7.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType8.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType9.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType10.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType11.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType12.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType13.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType14.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType15.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType16.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType17.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType18.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType19.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.creatorType20.subtitle";
};

const creatorTypeCards: CreatorTypeCard[] = [
    {
        image: "https://media.istockphoto.com/id/1992829718/photo/professor-teaching-about-it-and-pointing-at-a-user-experience-diagram-on-the-board.jpg?s=2048x2048&w=is&k=20&c=6jFp4msCcN6Q4g3vw_yfINyG5hEflr85KYEFe6oqUTA=",
        image_alt: "Educator",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType1.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType1.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1408797987/photo/chef-decorating-a-plate-while-working-in-the-kitchen-at-a-restaurant.jpg?s=2048x2048&w=is&k=20&c=-rqjstap13BdGhzbpGQ1KMccV9-LFye8KaoTmu_o-Mg=",
        image_alt: "Chef blogger",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType2.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType2.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1356153363/photo/colleagues-recording-podcast-together.jpg?s=2048x2048&w=is&k=20&c=N_GRvRxglFB2GhHiPt_ksmc0OMYIrChz5BUCJUBVjUM=",
        image_alt: "Interviewer",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType3.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType3.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1098030286/photo/mixed-race-woman-singing-and-playing-guitar.jpg?s=2048x2048&w=is&k=20&c=Q7V1AgP7zw7l8ANibu5_UkjDyqTMFtAi2v6AYczgP5Q=",
        image_alt: "Cover artist",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType4.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType4.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1130310730/photo/female-vlogger-showing-cosmetic-accessories-while-recording-content.jpg?s=2048x2048&w=is&k=20&c=tDo8s-ArnR4FKLlUHKW3MkRiS-7WHEBLJYzBweMzsYY=",
        image_alt: "Product reviewer",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType5.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType5.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1401401410/photo/medical-training-class.jpg?s=2048x2048&w=is&k=20&c=baDN1gyw4MfPnXJyMAx4p9eGZTlH4rxSVRJKiGi6RFo=",
        image_alt: "Medical doctor",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType6.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType6.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/2148292148/photo/woman-laying-in-camper-van-overlooking-furka-pass-in-switzerland.jpg?s=2048x2048&w=is&k=20&c=xaVHvrQRPIwXSSplBcKGtqWh7ctNHIRKH7CK6Il1OrA=",
        image_alt: "Travel blogger",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType7.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType7.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/2166069646/photo/bird-watcher-and-photographer-man-taking-pictures-in-nature.jpg?s=2048x2048&w=is&k=20&c=qMHa8UPY_OovarNgV457SBkc3Q7VU9KkvZ88J2PRqAo=",
        image_alt: "Nature photographer",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType8.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType8.subtitle",
    },
    {
        image: "https://images.unsplash.com/photo-1560010954-0ea652c47dff?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        image_alt: "Musician",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType9.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.creatorType9.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1323621453/photo/yoga-instructor-making-vlog-at-home-assuming-one-legged-pigeon-pose.jpg?s=2048x2048&w=is&k=20&c=N3QMTMhfslSAI2s8hNjXTIPO_K7nLAZjsU6eixGwfJA=",
        image_alt: "Fitness instructor",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType10.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.creatorType10.subtitle",
    },
    {
        image: "https://media.istockphoto.com/id/1432135513/photo/cropped-image-of-creative-man-freelancer-working-with-graphic-table-and-computer-at-modern.jpg?s=2048x2048&w=is&k=20&c=6ESTrp0sTC65EwYkNdJHVNkZH8PqTPB_G3kWFNRqY3g=",
        image_alt: "Digital artist",
        title: "welcomePage.heroCarousel.creatorTypes.creatorType11.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.creatorType11.subtitle",
    },
    // {
    //     image: "https://images.unsplash.com/photo-1581368135153-a506cf13b1e1?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType12.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType12.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType13.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType13.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType14.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType14.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType15.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType15.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType16.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType16.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType17.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType17.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType18.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType18.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType19.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType19.subtitle",
    // },
    // {
    //     image: "https://images.unsplash.com/photo-1497015455546-1da71faf8d06?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     image_alt: "",
    //     title: "welcomePage.heroCarousel.creatorTypes.creatorType20.title",
    //     subtitle:
    //         "welcomePage.heroCarousel.creatorTypes.creatorType20.subtitle",
    // },
];

interface Props extends HTMLAttributes<any> {
    handleScrollClick: () => void;
}

const HeroCarousel = ({ id, handleScrollClick }: Props) => {
    const { t } = useTranslation();
    const renderedCreatorTypeCards = creatorTypeCards.map((item, index) => (
        <div
            id="b78a7adb-ccce-4e7a-9648-0756e0ea0420"
            className={`carousel-item ${index === 0 && "active"}`}
        >
            <img
                id="8e6601ce-eb4b-43a8-be3a-98fc58d1994d"
                src={item.image}
                className="d-block w-100 vh-100"
                style={{ objectFit: "cover" }}
                alt={item.image_alt}
            />
            <div
                id="dd57f3fc-d64d-43e1-bd4c-3ad75c52107c"
                className={classes["color-overlay"]}
            />
            <div
                id="575e5ee4-45da-4fbb-a63d-5dd81d0ab974"
                className={`carousel-caption h-100 d-flex flex-column justify-content-center align-items-center ${classes["carousel-caption"]}`}
                style={{ bottom: 0 }}
            >
                <div
                    id="3cf3884c-1600-4a3c-9bde-a505af83598d"
                    className="d-flex flex-column gap-3 align-items-center"
                    style={{ paddingTop: "90px" }}
                >
                    <span
                        id="bda33e8d-5388-4e3d-b5cb-01d0943797ee"
                        className={classes["carousel-caption-title"]}
                    >
                        {t(item.title)}
                    </span>
                    <span
                        id="b3f3e98d-d888-45c2-abaf-9b642fd21676"
                        className={`${classes["carousel-caption-subtitle"]} mb-3`}
                    >
                        {t(item.subtitle)}
                    </span>
                    <Link
                        id="228d6720-8eca-4f60-95db-5980844819b2"
                        to={"/signup"}
                        className={`btn btn-danger ${classes["carousel-caption-link"]}`}
                    >
                        {t("welcomePage.joinAblebees")}
                    </Link>
                    <Button
                        id="04e71a16-13ee-44b8-9a14-660f95751368"
                        className="btn btn-sm btn-link text-decoration-none text-white mt-5"
                        onClick={handleScrollClick}
                    >
                        <div className="d-flex align-items-center gap-2">
                            <FaAnglesDown id="04e71a16-13ee-44b8-9a14-660f95751368-icon" />
                            Scroll to learn more
                            <FaAnglesDown id="04e71a16-13ee-44b8-9a14-660f95751368-icon" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    ));

    const renderedCarouselIndicators = creatorTypeCards.map((item, index) => (
        <Button
            id={`be6a7c90-b96d-419b-b731-49022ea43189-${index}`}
            type="button"
            data-bs-target={`#${id}`}
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
        />
    ));

    return (
        <div
            id={id}
            className="carousel slide carousel-fade vh-100"
            data-bs-ride="carousel"
        >
            <div
                id="31ec720b-32c9-4ef3-8afa-d8722a58ed5b"
                className="carousel-indicators"
                style={{ bottom: "6rem" }}
            >
                {renderedCarouselIndicators}
            </div>
            <div
                id="2cb58f1b-2447-4a5f-9845-c2f4835e933d"
                className="carousel-inner"
            >
                {renderedCreatorTypeCards}
            </div>
            <Button
                id="af090bb8-9d64-421d-bc21-1435041b8444"
                className="carousel-control-prev"
                type="button"
                data-bs-target={`#${id}`}
                data-bs-slide="prev"
            >
                <span
                    id="af090bb8-9d64-421d-bc21-1435041b8444-icon"
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span
                    id="af090bb8-9d64-421d-bc21-1435041b8444-text"
                    className="visually-hidden"
                >
                    Previous
                </span>
            </Button>
            <Button
                id="a57c5c3b-fe75-4904-87bd-4664939cb96b"
                className="carousel-control-next"
                type="button"
                data-bs-target={`#${id}`}
                data-bs-slide="next"
            >
                <span
                    id="a57c5c3b-fe75-4904-87bd-4664939cb96b-icon"
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span
                    id="a57c5c3b-fe75-4904-87bd-4664939cb96b-text"
                    className="visually-hidden"
                >
                    Next
                </span>
            </Button>
            {/*<div*/}
            {/*    id="2bc1240d-5ddc-4ef5-81ce-847dfa0cf541"*/}
            {/*    className="d-flex w-100 justify-content-center"*/}
            {/*    style={{*/}
            {/*        position: "absolute",*/}
            {/*        bottom: "2.5rem",*/}
            {/*        zIndex: 100,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        id="04e71a16-13ee-44b8-9a14-660f95751368"*/}
            {/*        className="btn btn-outline-light"*/}
            {/*        onClick={handleScrollClick}*/}
            {/*    >*/}
            {/*        <FaAnglesDown id="04e71a16-13ee-44b8-9a14-660f95751368-icon" />*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
};

export default HeroCarousel;
